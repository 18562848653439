/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Link from 'next/link';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import Carousel, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import api from 'services/api';

import { setBackgroundColorHeader } from 'store/modules/config';
import { IBannerProps } from 'store/modules/showcase';

import { Container } from './styles';

interface IBanner {
  banners: IBannerProps[];
}

const Banner: React.FC<IBanner> = ({ banners }) => {
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState('web');

  const sliderRef = useRef(null);

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
  };

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!banners?.length) {
      if (banners[0].backgroundColor) {
        dispatch(setBackgroundColorHeader(banners[0].backgroundColor));
      }
    } else {
      dispatch(setBackgroundColorHeader(null));
    }
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 768) {
        setWindowWidth('web');
      } else {
        setWindowWidth('mobile');
      }
    }

    return () => {
      dispatch(setBackgroundColorHeader(null));
    };
  }, [dispatch, banners]);

  // const handleChangeBackgroundColor = useCallback(
  //   (previousItemIndex: number, nextItemIndex: number) => {
  //     // eslint-disable-next-line no-extra-boolean-cast
  //     if (!!banners?.length) {
  //       if (banners[nextItemIndex].backgroundColor) {
  //         dispatch(
  //           setBackgroundColorHeader(banners[nextItemIndex].backgroundColor),
  //         );
  //       }
  //     }
  //   },
  //   [dispatch, banners],
  // );

  const handleBanner = useCallback(async (banner: IBannerProps, index) => {
    await api.put(`banners/clique/${banner.id}`);

    let creative_slot;

    if (banner.tipo == 'BOX') creative_slot = `banner_box${index + 1}`;
    else if (banner.tipo == 'TARJA') creative_slot = `banner_tarja${index + 1}`;
    else if (banner.tipo == 'TOPO') creative_slot = `banner_topo${index + 1}`;
    else if (banner.tipo == 'VITRINE')
      creative_slot = `banner_vitrine${index + 1}`;
    else creative_slot = `banner_full${index + 1}`;

    const campanha = {
      promotionId: banner.campanha,
      promotionName: banner.campanha,
      creative_name: banner.imagemUrl,
      creative_slot,
      itemListName: banner.titulo,
    };

    Cookies.set('@Pet:campanha', JSON.stringify(campanha));
  }, []);

  // eslint-disable-next-line no-extra-boolean-cast
  if (!banners?.length) return null;
  return (
    <Container>
      <Carousel {...settings} ref={sliderRef}>
        {banners.map((banner, index) => (
          <div
            key={`${banner.id}-${banner.titulo}`}
            onClick={() => handleBanner(banner, index)}
          >
            <Link
              href={`${banner.redirecionarUrl}?campanha=true` || '/'}
              passHref
            >
              {banner.abrirNovaJanela ? (
                <a
                  href={banner.redirecionarUrl}
                  title={banner.titulo}
                  target={banner.abrirNovaJanela ? '_blank' : ''}
                  rel={banner.abrirNovaJanela ? 'noopener noreferrer' : ''}
                >
                  <img
                    loading="lazy"
                    src={
                      windowWidth === 'web'
                        ? banner.imagemUrl
                        : banner.imagemMobileUrl
                    }
                    alt={banner.titulo}
                  />
                </a>
              ) : (
                <a href={banner.redirecionarUrl || '/'}>
                  <img
                    loading="lazy"
                    title={banner.titulo}
                    src={
                      windowWidth === 'web'
                        ? banner.imagemUrl
                        : banner.imagemMobileUrl
                    }
                    alt={banner.titulo}
                  />
                </a>
              )}
            </Link>
          </div>
        ))}
      </Carousel>

      <button onClick={handlePrev} className="nav-left">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
        </svg>
      </button>

      <button onClick={handleNext} className="nav-right">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        </svg>
      </button>
    </Container>
  );
};

export default Banner;
