import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  .nav-left, .nav-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    svg {
      width: 100%;
      height: auto;
      fill: var(--primary-color);
    }
  }

  .nav-left  {
    left: 30px;
  }

  .nav-right {
    right: 30px;
  }

  img {
    max-width: 100%;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
  }

  a {
    display: block;
  }
  .slick-track {
    width: 1px;
  }

  .slick-active {
    z-index: 2;
  }

  .slick-dots {
    bottom: 0.8rem;

    button:before {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      content: '';
      background: #fff;
    }
  }
`;
